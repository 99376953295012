.code-card img {
  height: 50px;
}

#languages {
  padding-bottom: 0;
}
.container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0;
}

.proficient {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.proficient h4 {
  margin: 0 auto;  
}
.code-card {
  margin: auto;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  transition: all .2s ease-in-out;
} 

.code-card:hover {
  transition: all .2s ease-in-out;
  transform: scale(1.5);
}
