@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

.logo {
  height: 50px;
}

.navbar {
  background-color: white;
  position: fixed;
  width: 100%;
  /* height: 50px; */
  z-index: 100;
}

.container-nav {
  align-items: center;
  padding-bottom: 0px;
}

.top {
  padding-top: 15px;
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.topcard {
  padding: 12px 12px 12px 12px ;
  border-radius: 10px;
}

.bottom {
  padding-top: 15px;
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-around;
}

.user_table {
  width: 100rem;
  margin: 10px;
}

.cover {
  margin: 0 auto;
  width: 60%;
  text-align: center;
  margin-top: 100px;
}
.home {
  height: 100%;
}

@media (max-width: 768px) {
  .cover {
    width: 80%;
  }
}

.nav-link {
  color: black;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: .25em;
  display: inline-block;
  padding: 15px;
  position: relative;
}

.nav-link:hover::after {
  width: 100%;
  right: 0;
}
.nav-link:hover {
  color: black;
}

.nav-link::after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 4px;
  right: 0;
  position: absolute;
  background: #002D04;
  transition: width .5s ease 0s, right .5s ease 0s;
  width: 0;
}

.nav {
  float: left;
}

#home {
  height: 95vh;
  background: linear-gradient(to bottom, white, white, white, white,  #002D04);
  width: 100vw;

}

#about {

}

.home-section {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding-left: 10vw;
  font-family: 'Josefin Sans', sans-serif;
}

.home-section h1 {
  font-size: 10ch;
  font-weight: bold;
}

.first-name {
  color: white;
  text-shadow: -1px -1px 0 #002D04, 1px -1px 0 #002D04, -1px 1px 0 #002D04, 1px 1px 0 #002D04;
}


@media (max-width: 900px) {
  .home-section h1 {
    font-size: 50px;
    font-weight: bold;
  }
  #home {
    height: 60vh;
    -ms-transform: translateY(-0%);
    transform: translateY(-0%);
  }
  .home-section {
    padding-left: 0;
  }
}

.last-name {
  color: #002D04;
}


.social-share {
  float: left;
  padding-left: 0px;
}

.social-share li {
  float: left;
  display: block;
  text-align: center;
  text-decoration: none;
  padding: 0 10px 0 10px;
}

.social-share li a {
  color: #002D04;
}

.social-share a:hover {
  color: #526F35;
}

#about {
  display: flex;
  flex-wrap: inherit;
  justify-content: space-between;
  background-color: #002D04;
}

#about .about-one {
  width: 30vw;
}
#about .about-two {
  width: 70vw;
  padding-left: 10vw;
  color: white;
}

.about-two p {
  font-size: large;
  font-family: 'Lato', sans-serif;
}

#about h1 {
  color: white;
  /* text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white; */
  font-size: 3.75rem;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 300;
}

@media (max-width: 900px) {
  #about {
    flex-direction: column; /* Change to column for stacking */
    align-items: center; /* Center items when stacked */
  }
  #about .about-one {
    width: 100%;
  }
  #about .about-two {
    width: 100%;
    padding-left: 0;
  }
}
section {
  padding: 8vh 10vw 8vh 10vw;
}

#languages h4 {
  color: #002D04;
  font-size: 4ch;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 300;
}


button {
  position: fixed;
  padding: 1rem 1rem;
}

.scroll-up {
  position: fixed;
  bottom: 40px;
  right: 40px;
}

section {
  padding-top: 100px;
  padding-bottom: 100px;
}

.navbar {
  padding-bottom: 5px;
}

.is-active::after {
  width: 100%;
  right: 0;
}

.container-nav img {
  height: 50px;
}

.logo-nav {
  transition: all .2s ease-in-out;

}

.logo-nav:hover {
  transition: all .2s ease-in-out;
  transform: scale(1.5);
}

/* CSS for the Banner component */
.home-section {
  display: flex; /* Use flexbox to arrange the content horizontally */
  justify-content: space-between; /* Space the content evenly */
  align-items: center; /* Center vertically */

}

.left-section {
  flex: 1; /* Make the left section take up all available horizontal space */
}

.right-section {
  flex: 1; /* Make the right section take up all available horizontal space */
}